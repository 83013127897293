<template>
  <div class="order-product">
    <el-tabs v-model="activeName">
      <el-tab-pane label="产品库" name="1">
        <el-form ref="ruleForm" :model="ruleForm">
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item prop="codeOrName">
                <el-input
                  v-model.trim="ruleForm.codeOrName"
                  placeholder="搜索产品名称/SKU ID/规格"
                  @keyup.enter.native="search"
                  maxlength="50"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-button @click="search" type="primary">搜索</el-button>
            <el-button @click="reset">重置</el-button>
          </el-row>
        </el-form>
        <div class="container">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            height="520"
            @selection-change="selectionChange"
          >
            <el-table-column type="selection" width="40" />
            <el-table-column
              width="80"
              align="center"
              label="产品图片"
              prop="imgMain"
            >
              <template slot-scope="scope">
                <div class="img-wrap" @click="handlePreview(scope.row.imgMain)">
                  <img :src="scope.row.imgMain" />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品编码"
              prop="skuId"
              width="120"
            >
              <template slot-scope="scope">
                <div class="page-link">
                  <router-link
                    :to="`/product/ProductView?proId=${
                      scope.row.productId
                    }&noReturn=${true}`"
                    target="_blank"
                  >
                    {{ scope.row.skuId }}
                  </router-link>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品名称"
              prop="nameCn"
              show-overflow-tooltip
            />
            <el-table-column align="center" label="规格" show-overflow-tooltip>
              <template slot-scope="scope">
                {{
                  formatColumn(
                    scope.row.specsType,
                    scope.row.specsValue1,
                    scope.row.specsValue2
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品类型"
              prop="standardCustomized"
            />
            <el-table-column
              align="center"
              label="产品运营"
              prop="productBelonger"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{
                  scope.row.productBelonger ? scope.row.productBelonger : '/'
                }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="创建人"
              prop="creator"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.creator ? scope.row.creator : '/' }}
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-pagination
          :current-page="page.pageNo"
          :page-size="page.pageLe"
          :total="total"
          @current-change="pageChange"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          @size-change="sizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </el-tab-pane>

      <el-tab-pane label="辅料库" name="2">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input
              v-model="queryString"
              placeholder="搜索产品名称/产品编码"
              @keyup.enter.native="search2"
              maxlength="50"
              clearable
            />
          </el-col>
          <el-button @click="search2" type="primary">搜索</el-button>
          <el-button @click="reset2">重置</el-button>
        </el-row>

        <!-- 辅料库Table -->
        <el-table
          class="mt10"
          ref="multipleTable2"
          border
          :data="tableData2"
          @selection-change="selectionChange2"
          height="520"
        >
          <el-table-column type="selection" width="40" />
          <el-table-column
            align="center"
            label="产品图片"
            prop="productImg"
            width="100"
          >
            <template slot-scope="scope">
              <div class="img-wrap">
                <el-badge
                  v-show="
                    scope.row.productImg &&
                    scope.row.productImg.split(',').length > 1
                  "
                  :value="
                    scope.row.productImg
                      ? scope.row.productImg.split(',').length
                      : ''
                  "
                ></el-badge>
                <el-image
                  v-if="scope.row.productImg"
                  :src="scope.row.productImg.split(',')[0]"
                  class="c_pointer"
                  @click="handlePreview(scope.row.productImg.split(','))"
                ></el-image>
                <span v-else>--</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="产品编码">
            <template slot-scope="scope">
              <div class="page-link">
                {{ scope.row.accessoriesNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="产品名称"
            prop="nameCn"
            show-overflow-tooltip
          />
          <el-table-column align="center" label="创建人" prop="creatorName" />
        </el-table>

        <el-pagination
          :current-page="page2.pageNo"
          :page-size="page2.pageLe"
          :total="total2"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          @current-change="pageChange2"
          @size-change="sizeChange2"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </el-tab-pane>
    </el-tabs>

    <div class="text-center mt20">
      <el-button type="primary" @click="save()">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </div>
</template>

<script>
import costAdjustInteractor from '@/core/interactors/storage/cost-adjustment'
import { ProductListInteractor } from '@/core'
export default {
  props: {},
  data() {
    return {
      activeName: '1',
      tableData: [],
      page: {
        pageLe: 10,
        pageNo: 1,
      },
      total: 0,
      ruleForm: {
        codeOrName: '',
      },
      queryString: '', // 辅料库搜索参数
      page2: {
        pageLe: 10,
        pageNo: 1,
      },
      total2: 0,
      tableData2: [],
      selectRows1: [], // 产品库选中的数据
      selectRows2: [], // 辅料库选中的数据
      showViewer: false, //图片预览参数
      imgUrl: [], //图片预览url
    }
  },
  created() {
    this.getList()
    this.getList2()
  },
  methods: {
    async getList() {
      const res = await costAdjustInteractor.getAuditProcessProduct(
        this.ruleForm.codeOrName,
        this.page
      )

      if (res && res.code === '000000') {
        this.tableData = res.data.data || []
        this.total = res.data.total || 0
      }
    },

    // 获取辅料库
    async getList2() {
      const params = {
        ...this.page2,
        queryString: this.queryString,
      }

      let res = await ProductListInteractor.subProductList(params)
      this.tableData2 = res?.data?.list || []
      this.total2 = res?.data?.total || 0
    },

    // 搜索
    search() {
      this.page.pageLe = 10
      this.page.pageNo = 1
      this.getList()
    },

    // 辅料库搜索
    search2() {
      this.page2.pageLe = 10
      this.page2.pageNo = 1
      this.getList2()
    },

    // 重置
    reset() {
      this.ruleForm.codeOrName = ''
      this.search()
    },

    // 重置辅料库
    reset2() {
      this.queryString = ''
      this.search2()
    },

    // 数据格式处理
    formatColumn(type, value1, value2) {
      const arr = type.split(',')
      if (!arr[0]) {
        return '/'
      } else if (arr[0]) {
        return arr + ':' + value1
      } else {
        return arr[0] + ':' + value1 + '，' + arr[1] + ':' + value2
      }
    },

    // 选中
    selectionChange(rows) {
      this.selectRows1 = rows
    },

    // 辅料库复选框勾选
    selectionChange2(rows) {
      this.selectRows2 = rows.map((item) => {
        return {
          ...item,
          sonProductType: '1',
          productCode: item.accessoriesNumber,
          productId: item.productAccessoriesId,
          sku: item.accessoriesNumber,
          imgMain: item.productImg,
        }
      })
    },

    // 产品切换分页
    pageChange(val) {
      this.page.pageNo = val
      this.getList()
    },

    // 产品页码切换
    sizeChange(val) {
      this.page.pageLe = val
      this.getList()
    },

    // 辅料库分页切换
    pageChange2(val) {
      this.page2.pageNo = val
      this.getList2()
    },

    // 辅料库页码切换
    sizeChange2(val) {
      this.page2.pageLe = val
      this.getList2()
    },

    // 确认
    save() {
      if (!this.selectRows1.length && !this.selectRows2.length) {
        this.$message.warning('至少选择一条产品')
        return false
      }
      // 产品数据
      const data = this.selectRows1.map((item) => {
        return {
          ...item,
          specification: this.formatColumn(
            item.specsType,
            item.specsValue1,
            item.specsValue2
          ),
        }
      })
      // 辅料数据
      const data2 = this.selectRows2.map((item) => {
        return {
          nameCn: item.nameCn,
          productCode: '',
          nameEn: item.nameEn,
          productId: '',
          skuId: item.accessoriesNumber,
          specification: '',
          standardCustomized: '辅料',
        }
      })
      this.$emit('getProductData', data.concat(data2))
    },

    // 图片预览
    handlePreview(img) {
      this.imgUrl = [img]
      this.showViewer = true
    },

    // 关闭产品图片预览
    closeViewer() {
      this.showViewer = false
    },

    // 关闭
    close() {
      this.$emit('productClose')
      this.$refs['ruleForm'].resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
.order-product {
  margin-top: -15px;
}
.img-wrap {
  width: 45px;
  height: 45px;
  margin: 0 auto;
  img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    display: block;
    border-radius: 2px;
    cursor: pointer;
  }
}
::v-deep {
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-pagination {
    height: 36px;
  }
}
</style>
