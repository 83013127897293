<template>
  <div class="order-product">
    <el-form ref="ruleForm" :model="ruleForm">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item>
            <el-input
              v-model.trim="ruleForm.purchaseNumber"
              placeholder="采购单号"
              maxlength="50"
              clearable
              @keyup.enter.native="search"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item>
            <el-input
              v-model.trim="ruleForm.orderCode"
              placeholder="销售单号"
              maxlength="50"
              clearable
              @keyup.enter.native="search"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item>
            <el-input
              v-model.trim="ruleForm.warehouseInNumber"
              placeholder="入库单号"
              maxlength="50"
              clearable
              @keyup.enter.native="search"
            />
          </el-form-item>
        </el-col>
        <el-button @click="search" type="primary" class="ml5">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </el-row>
    </el-form>
    <div class="container">
      <el-table
        ref="multipleTable"
        border
        :data="tableData"
        height="560"
        @selection-change="selectionChange"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column type="selection" width="40" />
        <el-table-column
          label="入库单号"
          prop="warehouseInNumber"
          width="120"
        />
        <el-table-column label="入库批次" prop="lotNo" width="140" />
        <el-table-column label="入库类型" width="120">
          <template slot-scope="scope">
            {{ scope.row.warehouseType | warehouseTypeName }}
          </template>
        </el-table-column>
        <el-table-column label="入库仓库" prop="warehouseName" width="120" />
        <el-table-column label="采购单号" prop="purchaseNumber" width="120">
          <template slot-scope="scope">
            {{ scope.row.purchaseNumber || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="采购开发" prop="buyerName" width="120">
          <template slot-scope="scope">
            {{ scope.row.buyerName || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="销售单号" prop="orderCode" width="120">
          <template slot-scope="scope">
            {{ scope.row.orderCode || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="客户代表" prop="businessName" width="120">
          <template slot-scope="scope">
            {{ scope.row.businessName || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="入库数量" prop="warehouseInNum" width="120" />
        <el-table-column label="创建人" prop="creatorName" width="120" />
        <el-table-column label="入库时间" prop="warehouseInTime" width="180" />
      </el-table>
    </div>

    <el-pagination
      :current-page="page.pageNo"
      :page-size="page.pageLe"
      :total="total"
      @current-change="currentChange"
    />

    <div class="text-center mt20">
      <el-button type="primary" @click="save()">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </div>
</template>

<script>
import costAdjustInteractor from '@/core/interactors/storage/cost-adjustment'
import costAdjustDicts from '.././dict/index'
export default {
  props: ['sku'],
  data() {
    return {
      tableData: [],
      page: {
        pageLe: 10,
        pageNo: 1,
      },
      total: 0,
      ruleForm: {
        purchaseNumber: '', // 采购单号
        orderCode: '', // 销售单号
        warehouseInNumber: '', // 入库单号
      },
      selectRows: [], // 当前分页选中的数据
      showViewer: false, //图片预览参数
      imgUrl: [], //图片预览url
    }
  },
  created() {
    this.getList()
  },
  filters: {
    // 入库类型
    warehouseTypeName(val) {
      return costAdjustDicts.getWarehouseTypeName(val)
    },
  },
  methods: {
    async getList() {
      const res = await costAdjustInteractor.getCostAdjustLot(
        this.ruleForm,
        this.page,
        this.sku
      )
      if (res && res.code === '000000') {
        this.tableData = res.data.data || []
        this.total = res.data.total || 0
      }
    },

    // 搜索
    search() {
      this.page.pageNo = 1
      this.getList()
    },

    // 重置
    reset() {
      this.ruleForm = this.$options.data().ruleForm
      this.search()
    },

    // 选中
    selectionChange(rows) {
      this.selectRows = rows
    },

    // 切换分页
    currentChange(val) {
      this.page.pageNo = val
      this.getList()
    },

    // 确认
    save() {
      if (!this.selectRows.length) {
        this.$message.warning('至少选择一条批次')
        return false
      }
      this.$emit('getStorageData', this.selectRows)
    },

    // 关闭
    close() {
      this.$emit('storageClose')
      this.$refs['ruleForm'].resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
.order-product {
  margin-top: -15px;
}
</style>
