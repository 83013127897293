<template>
  <div class="custom-container">
    <el-row>
      <el-page-header
        @back="goBack()"
        :content="costAdjustId ? '成本调整编辑' : '成本调整新增'"
      ></el-page-header>
    </el-row>

    <el-form class="mt10" :model="form" :rules="rules" ref="ruleForm">
      <el-row>
        <el-col :span="6">
          <el-form-item
            label="调整类型"
            prop="costWarehouseType"
            label-width="80px"
          >
            <el-select
              v-model="form.costWarehouseType"
              placeholder="调整类型"
              :disabled="true"
            >
              <el-option
                v-for="item in costAdjustStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="创建人" label-width="100px">
            <el-input
              v-model="userInfo.userName"
              placeholder="创建人"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注说明" label-width="80px">
            <el-input
              type="textarea"
              v-model="form.remark"
              :rows="3"
              placeholder="请输入"
              maxlength="500"
              show-word-limit
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="flex-btn mt20">
        <h4>产品列表</h4>
        <el-button type="primary" @click="productVisible = true">
          选择产品
        </el-button>
      </div>
      <el-table
        :data="form.costProductAdjustDTOS"
        border
        :summary-method="getSummaries"
        show-summary
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        class="mt20 dyn-table"
      >
        <el-table-column prop="sku" label="产品编码" width="120">
          <template slot-scope="scope">
            <div class="page-link">
              <router-link
                :to="`/product/ProductView?proId=${
                  scope.row.productId
                }&noReturn=${true}`"
                target="_blank"
              >
                {{ scope.row.sku }}
              </router-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="productCn"
          label="产品名称"
          min-width="180"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="ell">{{ scope.row.productCn }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="specification"
          label="规格"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="ell">
              {{ scope.row.specification || '--' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="lotNo"
          label="入库批次"
          width="190"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'costProductAdjustDTOS.' + scope.$index + '.lotNo'"
              :rules="[
                {
                  required: true,
                  message: '请选择入库批次',
                },
              ]"
            >
              <div class="column-sty">
                <el-input
                  v-model="form.costProductAdjustDTOS[scope.$index].lotNo"
                  placeholder="请选择入库批次"
                  disabled
                ></el-input>
                <i
                  class="el-icon-circle-plus-outline"
                  @click="
                    openStorage(
                      form.costProductAdjustDTOS[scope.$index].sku,
                      scope.$index
                    )
                  "
                ></i>
              </div>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="warehouseType" label="入库类型" width="100">
          <template slot-scope="scope">
            {{ scope.row.warehouseType | warehouseTypeName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="warehouseInNum"
          label="数量"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="priceExcludingTax"
          label="单价"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="totalPriceExcluding"
          label="成本"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="totleAdjustPrice"
          label="已调整金额"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="adjustPrice"
          label="本次调整金额"
          label-class-name="required-th"
          width="160"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'costProductAdjustDTOS.' + scope.$index + '.adjustPrice'"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'blur',
                },
                {
                  message: '格式错误',
                  pattern: /(^[1-9-](\d+)?(\.\d{1,3})?$)|(^\d\.\d{1,3}$)/,
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                :disabled="!form.costProductAdjustDTOS[scope.$index].lotNo"
                v-model="form.costProductAdjustDTOS[scope.$index].adjustPrice"
                @blur="keepDecimal(scope.$index)"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="priceExcludingTaxed"
          label="调整后单价"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="totalPriceExcluded"
          label="调整后成本"
          width="120"
        ></el-table-column>
        <el-table-column prop="purchaseNumber" label="采购单号" width="160">
          <template slot-scope="scope">
            {{ scope.row.purchaseNumber || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="buyerName" label="采购开发" width="120">
          <template slot-scope="scope">
            {{ scope.row.buyerName || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="产品备注" min-width="200">
          <template slot-scope="scope">
            <el-form-item>
              <el-input
                type="textarea"
                maxlength="500"
                show-word-limit
                placeholder="请输入"
                v-model="form.costProductAdjustDTOS[scope.$index].remark"
                :disabled="!form.costProductAdjustDTOS[scope.$index].lotNo"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="80">
          <template slot-scope="scope">
            <el-button
              class="icon-r"
              icon="el-icon-delete"
              circle
              @click="del(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <p class="mt20 accessory">附件</p>
      <ErpUpload
        ref="ErpUpload"
        :uploadParams="uploadParams"
        @uploadList="uploadList"
        @initUpload="initUpload"
      ></ErpUpload>
    </el-form>

    <div class="oper-btn">
      <el-button @click="Cancel" type="default">取消</el-button>
      <el-button @click="Submit" type="primary" :loading="loading">
        确认
      </el-button>
    </div>

    <!-- 选取产品 -->
    <el-dialog
      top="10vh"
      width="1200px"
      title="选择产品"
      :visible.sync="productVisible"
      :close-on-click-modal="false"
    >
      <choose-product
        v-if="productVisible"
        @getProductData="getProductData"
        @productClose="productVisible = false"
      ></choose-product>
    </el-dialog>

    <!-- 选择入库批次 -->
    <el-dialog
      top="10vh"
      width="1200px"
      title="选择入库批次"
      :visible.sync="storageVisible"
      :close-on-click-modal="false"
    >
      <choose-storage
        v-if="storageVisible"
        :sku="sku"
        @getStorageData="getStorageData"
        @storageClose="storageVisible = false"
      ></choose-storage>
    </el-dialog>

    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />
  </div>
</template>

<script>
  import costAdjustInteractor from '@/core/interactors/storage/cost-adjustment'
  import costAdjustDicts from './dict/index'
  import chooseProduct from './components/choose-product.vue'
  import chooseStorage from './components/choose-storage.vue'
  import AuditChoose from '@/components/AuditChoose'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      chooseProduct,
      chooseStorage,
      AuditChoose,
    },
    data() {
      return {
        sku: '',
        index: 0, // 选择入库批次的位置
        costAdjustId: '', // 成本调整单id
        form: {
          costWarehouseType: '2', // 调整类型（1：出库；2：入库）
          remark: '', // 备注说明
          fileUrl: '', // 附件
          costProductAdjustDTOS: [],
          auditObjectDTO: {}, // 审核模板
        },
        rules: {
          costWarehouseType: [
            { required: true, message: '请输入', trigger: 'blur' },
          ],
        },
        costAdjustStatusList: costAdjustDicts.costAdjustStatusList, // 调整类型下拉项
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        productVisible: false,
        storageVisible: false,
        loading: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    filters: {
      warehouseTypeName(val) {
        return costAdjustDicts.getWarehouseTypeName(val)
      },
    },
    created() {
      this.costAdjustId = this.$route.query?.id
      this.costAdjustId && this.getEdit()
    },
    methods: {
      // 编辑获取详情
      async getEdit() {
        const res = await costAdjustInteractor.getCostAdjustDetail(
          this.costAdjustId
        )
        if (res && res.code === '000000') {
          this.form.remark = res.data?.remark
          this.form.fileUrl = res.data?.fileUrl
          this.form.costProductAdjustDTOS = res.data?.costProductAdjustVOS
          this.form?.fileUrl && this.initUpload(this.form.fileUrl)
        }
      },

      // 获取产品信息
      getProductData(data) {
        let [newArr, ids] = [[], []]

        this.form.costProductAdjustDTOS.forEach((item) => ids.push(item.sku))
        for (let i = 0; i < data.length; i++) {
          if (!ids.includes(data[i].skuId)) {
            newArr.push(data[i])
          }
        }
        const _arr = newArr.map((item, i) => {
          return {
            productCn: item.nameCn,
            productCode: item.productCode,
            productEn: item.nameEn,
            productId: item.productId,
            sku: item.skuId,
            specification: item.specification,
            standardCustomized: item.standardCustomized,
            warehouseInNum: 0,
            priceExcludingTax: 0,
            totalPriceExcluding: 0,
            totleAdjustPrice: 0,
            adjustPrice: 0,
            priceExcludingTaxed: 0,
            totalPriceExcluded: 0,
            purchaseNumber: '',
            buyerName: '',
          }
        })

        this.form.costProductAdjustDTOS =
          this.form.costProductAdjustDTOS.concat(_arr)
        this.productVisible = false
      },

      // 显示入库批次
      openStorage(sku, i) {
        this.sku = sku
        this.index = i
        this.storageVisible = true
      },

      // 获取入库批次
      getStorageData(data) {
        let [_arr, product, flag] = [
          [],
          this.form.costProductAdjustDTOS[this.index],
          true,
        ]

        this.form.costProductAdjustDTOS.forEach((item) =>
          _arr.push(item.sku + '_' + (item.lotNo || '000'))
        )

        for (let i = 0; i < data.length; i++) {
          if (_arr.includes(product.sku + '_' + data[i].lotNo)) {
            this.$message.warning(`入库批次${data[i].lotNo}重复！`)
            flag = false
            break
          }
        }

        // 同一个成本调整单内、同一批次不能重复选择
        if (flag) {
          this.form.costProductAdjustDTOS.splice(this.index, 1)
          for (let i = 0; i < data.length; i++) {
            const _obj = {
              orderProductUniqueId: data[i].orderProductUniqueId,
              adjustPrice: data[i].adjustPrice,
              businessId: data[i].businessId,
              businessName: data[i].businessName,
              buyerId: data[i].buyerId,
              buyerName: data[i].buyerName,
              lotNo: data[i].lotNo,
              orderCode: data[i].orderCode,
              orderId: data[i].orderId,
              parentSku: data[i].parentSku,
              priceExcludingTax: data[i].priceExcludingTax?.toFixed(3),
              priceExcludingTaxed: 0,
              productCn: product.productCn,
              productCode: product.productCode,
              productEn: product.productEn,
              productId: product.productId,
              purchaseNumber: data[i].purchaseNumber,
              purchaseOrderId: data[i].purchaseOrderId,
              remark: '',
              sku: product.sku,
              specification: product.specification,
              standardCustomized: product.standardCustomized,
              stockLotId: data[i].stockLotId,
              totalPriceExcluded: 0,
              totalPriceExcluding: data[i].totalPriceExcluding?.toFixed(3),
              totleAdjustPrice: data[i].totleAdjustPrice?.toFixed(3),
              warehouseInNum: data[i].warehouseInNum,
              warehouseNumber: data[i].warehouseInNumber,
              warehouseType: data[i].warehouseType,
            }

            this.form.costProductAdjustDTOS.splice(this.index, 0, _obj)
            this.storageVisible = false
          }
        }
      },

      // 合计
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if ([7, 8, 9, 11].includes(index)) {
            const values = data.map((item) => Number(item[column.property]))
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              sums[index] = sums[index].toFixed(3)
            } else {
              sums[index] = ''
            }
          } else {
            sums[index] = ''
            return
          }
        })

        return sums
      },

      // 本次调整金额自动保留小数位
      keepDecimal(i) {
        let list = this.form.costProductAdjustDTOS

        if (
          list[i].adjustPrice < 0 &&
          Math.abs(list[i].adjustPrice) > list[i].totalPriceExcluding
        ) {
          list[i].adjustPrice = ''
          return false
        }
        if (Math.abs(list[i].adjustPrice) >= 0.001) {
          list[i].adjustPrice = Number(list[i].adjustPrice).toFixed(3)
        } else {
          list[i].adjustPrice = ''
          return false
        }

        // 调整后成本:成本+本次调整金额+已调整金额
        list[i].totalPriceExcluded = (
          Number(list[i].totalPriceExcluding || 0) +
          Number(list[i].adjustPrice) +
          Number(list[i].totleAdjustPrice || 0)
        ).toFixed(3)

        // 调整后单价:调整后成本/数量
        list[i].priceExcludingTaxed = (
          Number(list[i].totalPriceExcluded) / list[i].warehouseInNum
        ).toFixed(3)
      },

      // 确认
      Submit() {
        if (this.form.costProductAdjustDTOS.length === 0) {
          this.$message.warning('请选择一条产品！')
          return false
        }

        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let auditParams = {
              auditType: 15,
              status: 0,
              tenantId: this.userInfo.tenantId,
            }
            this.pubApi.getAuditTemplates(auditParams).then((response) => {
              if (!response.data.length) {
                this.$baseMessage(
                  '请联系管理员配置审核模板',
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
                this.$refs['AuditChoose'].showAddEdit(auditParams)
              }
            })
          }
        })
      },

      // 保存审核模板信息
      saveClick(data) {
        this.form.auditObjectDTO = data
        this.save()
      },

      // 新增or保存
      async save() {
        this.loading = true
        try {
          const res = await costAdjustInteractor.saveCostAdjust(
            this.form,
            this.costAdjustId
          )
          if (res && res.code === '000000') {
            this.$message.success(
              `${this.costAdjustId ? '编辑成功！' : '新增成功！'}`
            )
            this.$router.push('/stock-center/cost-adjustment/cost-adjustment')
          }
        } catch (err) {
        } finally {
          this.loading = false
        }
      },

      // 取消
      Cancel() {
        this.goBack()
        this.form = this.$options.data().form
      },

      // 删除产品
      del(i) {
        this.$confirm(`是否确认删除?`, '提示', { type: 'warning' })
          .then(() => {
            this.form.costProductAdjustDTOS.splice(i, 1)
          })
          .catch(() => {})
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.fileUrl = JSON.stringify(fileList)
      },

      // 回显附件
      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },

      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
    },
  }
</script>

<style scoped lang="scss">
  .el-form {
    padding-right: 5px;
    height: calc(100vh - 190px);
    overflow: auto;
  }
  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
  }
  .column-sty {
    display: flex;
    .el-input {
      padding-right: 5px;
    }
    i {
      font-size: 24px;
      cursor: pointer;
      margin-top: 5px;
      color: #409eff;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
    .el-table__footer-wrapper {
      .has-gutter {
        height: 40px;
        font-family: 'PingFang Bold';
        .required-th::before {
          display: none;
        }
        td {
          text-align: center;
        }
      }
    }
  }
</style>
